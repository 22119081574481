@charset "utf-8";

// If need, change your variables before importing Bulma
$body-background-color: #f1f1f1;
$footer-background-color: #e487a1;
$navbar-background-color: #f1f1f1;
$navbar-item-hover-color: #e487a1;
$navbar-item-hover-background-color: inherit;
$navbar-height: 5.5rem;
$navbar-item-img-max-height: 4.5rem;
$modal-content-width: 920px;
$primary: #932d61;
$secondary: #eeb6bb;

@import "~bulma/bulma.sass";
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:100,400,700');

// Customize CSS
@keyframes heartbeat { 20% { font-size: 20px; } 40% { font-size: 20px; } }
@keyframes rotate360 { 100% { transform: rotate(360deg) } }

html {
  scroll-behavior: smooth;
}

body {
  letter-spacing: 1px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
	transition: color .5s ease;
}

a:hover {
  color: #e487a1;
}

ol > li {
  padding-bottom: 30px;
}

div.anchor {
  display: block; 
  position: relative; 
  top: -5.5rem; 
  visibility: hidden; 
}

nav {
  padding: 0 20px;
}

.social a {
  margin: 0 10px;
  font-size: 2em;
}

.social a:hover {
  color: #f1f1f1;
}

.footer {
  background-image: linear-gradient(to right, #e487a1 0%, #eeb6bb 100%);
}

.heartbeat {
  color: #e2264d;
  width: 20px !important;
  margin: 0 0 0 5px;
  will-change: font-size;
  animation: heartbeat 0.5s infinite alternate;
}

.btn-primary {
	background-image: linear-gradient(to right, #e487a1 0%, #eeb6bb 100%);
	border-radius: 40px;
  box-sizing: border-box;
	color: #e487a1;
	display: block;
	height: 40px;
	padding: 2px;
	position: relative;
	z-index: 2;
}

.btn-primary:hover {
	color: #f1f1f1;
}

.btn-primary span {
	align-items: center;
	background: #f1f1f1;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	height: 100%;
	transition: background .5s ease;
	width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-primary:hover span {
	background: transparent;
}

.btn-primary.is-loading {
  color: transparent !important;
  pointer-events: none;
}

.btn-primary.is-loading:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 45%;
  box-shadow: 0px 0px 0 1px #f1f1f1;
  position: absolute;
  border-radius: 50%;
  animation: rotate360 .5s infinite linear, exist .1s forwards ease;
}

.btn-primary.is-loading:before {
  content: "";
  border-radius: 50%;
  top: 50%;
  left: 45%;
  position: absolute;
  border: 1px solid #f1f1f1;
  border-right: 2px solid darken(#e487a1, 20%);
  animation: rotate360 .5s infinite linear, exist .1s forwards ease ;
}

.is-ellipsis-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.is-ellipsis-3 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}