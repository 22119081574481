@keyframes floating { from { transform: translate(0, 0px); } to { transform: translate(0, 10px); } }
@keyframes beam { from { opacity: 0.5; } to { opacity: 1; } }
@keyframes fadeout { from { opacity: 1; } to { opacity: 0; z-index: -100; } }

.header-image {
  content: "";
  position: relative;
  background-image: url("../../assets/images/header-img.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #f1f1f1;
}

.header-layer {
  background-color: #f1f1f1;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.navbar {
  transition: background-color .5s;
}

.title-line {
  position: relative;
  padding-bottom: 30px;
}

.title-line:after {
  content: '';
  display: block;
  border-bottom: 5px solid #000;
  width: 50px;
  margin: 0 auto;
  padding-top: 20px;
}

#home .title {
  color: #932d61;
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 2.5rem;
}

#floating {
  font-size: 30px;
  animation: floating 0.8s infinite alternate;
}

#about-us-content {
  background-image: linear-gradient(#e487a1, #f1f1f1);
}

#media-content {
  max-width: 1024px;
  margin: auto;
}

#our-services-content {
  background-image: linear-gradient(to right, #e487a1 0%, #eeb6bb 100%);
}

#our-doctor-content {
  background-image: linear-gradient(to right, #e487a1 0%, #eeb6bb 100%);
}

#our-doctor-content a:hover {
  color: #f1f1f1;
}

#our-doctor-content figure {
  height: 256px;
  width: 256px;
  margin: 0 auto;
}

#message-link a {
  margin: 0 10px;
  font-size: 2em;
}

#splashscreen {
  position: fixed;
  top: 0;
  z-index: 100;
  min-height: 480px;
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  animation: fadeout 0.4s linear 1 forwards;
  animation-delay: 3.5s;
}

.loaders {
  position: absolute;
	width: 100%;
	cursor: default;
	pointer-events: none;
  top: 33%;
}

.loaders-scale {
  height: 120px;
  display: block;
  margin: 0 auto;
  animation: scaleout 0.2s linear 1 forwards;
  animation-delay: 3.2s;
}

.loaders-beam {
  animation: beam 0.6s infinite alternate;
}

.group-content {
  margin-bottom: 2.5rem;
}

.media-display {
  display: flex;
  padding: 5px 0;
}

.media-left {
  width: 16px;
}

.cover-image {
  height: 300px !important;
  margin-bottom: 0;
}

.read-more {
  margin: 25px 0 0;
  justify-content: center;
}